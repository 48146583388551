$('#carouselAdvantages .carousel-item').each(function(){
    let next = $(this).next();
    if (!next.length) {
        next = $(this).siblings(':first');
    }
    next.find('.carousel-slide').children(':first-child').clone().appendTo($(this).find('.carousel-slide'));

    if (next.next().length>0) {
        next.next().find('.carousel-slide').children(':first-child').clone().appendTo($(this).find('.carousel-slide'));
    }
    else {
        $(this).siblings(':first').find('.carousel-slide').children(':first-child').clone().appendTo($(this).find('.carousel-slide'));
    }
});

if($("#carouselAdvantages").length != 0){
    let mq = window.matchMedia("(min-width: 992px)");
    $(window).on('resize', function() {
        $("#carouselAdvantages .carousel-indicators li:last-child" ).on( "click", function(e) {
            if (mq.matches) {
                if($('#carouselAdvantages .active').index('#carouselAdvantages .carousel-item') == 0){
                    turnAdv(e,"prev");
                }else{
                    turnAdvMoreThanOnce($('#carouselAdvantages .carousel-item').length-1,e);
                }
            }
        });
        
        $("#carouselAdvantages .carousel-indicators li:first-child" ).on( "click", function(e) {
            if (mq.matches) {
                if($('#carouselAdvantages .active').index('#carouselAdvantages .carousel-item') == $('#carouselAdvantages .carousel-item').length-1){
                    turnAdv(e,"next");
                }else{
                    turnAdvMoreThanOnce(0,e);
                }
            }
        });
        
        for(let i = 1; i < $("#carouselAdvantages .carousel-indicators li").length-1; i++){
            $("#carouselAdvantages .carousel-indicators li:nth-child("+(i+1)+")").on( "click", function(e) {
                if (mq.matches) turnAdvMoreThanOnce(i,e);
            });
        }
    }).resize();
}

function turnAdvMoreThanOnce(clicked:number, target:any){
    let active:number = $('#carouselAdvantages .active').index('#carouselAdvantages .carousel-item');
    let diff:number;
    if(active > clicked+1){
        turnAdv(target,"prev");
        diff = active - clicked - 1;
        turnAdvTimes(target,diff,"prev")
    } else if(active < clicked-1){
        turnAdv(target,"next");
        diff = clicked - active - 1;
        turnAdvTimes(target,diff,"next")
    }
}

function turnAdvTimes(target:any,diff:number,direction:string){
    let index:number = 0;
    $("#carouselAdvantages").on('slid.bs.carousel', function () {
        if(index < diff){
            turnAdv(target,direction);
            index++;
        }
    });
}

function turnAdv(e:any,direction:string){
    e.preventDefault();
    (<any>$('#carouselAdvantages')).carousel(direction);
}