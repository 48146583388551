$('a[href*="top-items"]').on('click', function(event){
    event.preventDefault();
    $('html,body').animate({scrollTop:$("#top-items").offset().top}, 400);
});

$('a[href="#top"]').on('click', function(event){
    event.preventDefault();
    $('html,body').animate({scrollTop:0}, 600);
});

$(window).on('load resize',function(){
    if($('.hero-scroll-down').length !== 0) {
        if($('.hero-scroll-down').offset().top <= ($('.hero-call-to-action').offset().top + $('.hero-call-to-action').height() + 10)){
            $('.hero-scroll-down').css("opacity","0");
        } else {
            $('.hero-scroll-down').css("opacity","1");
        }
    }
});
