$(window).on('scroll load', function() {
    $(".animation").each((_index, element) => {
        if(element != null){
            if($(element).children()[0].classList.contains("reveal") == false){
                let el_pos = $(element).offset().top;
                let scroll_pos = window.pageYOffset+(window.innerHeight/1.2);
                if(scroll_pos > el_pos){
                    element.classList.add('show');
                }
            }else {
                let el_pos = $(element).offset().top;
                let scroll_pos = window.pageYOffset+(window.innerHeight/1.2);
                if(scroll_pos > el_pos){
                    $(element).children()[0].classList.add("show");
                    let index = 1;
                    let i = setInterval(function() {
                        if($(element).children()[index] == undefined) {
                            clearInterval(i);
                        }
                        else {
                            $(element).children()[index].classList.add("show");
                            index++;
                        }
                    },200);
                }

            }
        }
    });
});
