let historyPlayed:boolean = false;
$(window).on('scroll', function() {
    if($('.history-year').length !== 0) {
        let end_years_pos = $('.history-year').last().offset().top + $('.history-year').last().height();
        let scroll_pos = window.pageYOffset+window.innerHeight;

        let time:number = 0;
        if(scroll_pos > end_years_pos && historyPlayed==false) {
            historyPlayed = true;
            $('.history-year').each((index, element) => {
                setTimeout(function() {
                    $(element).find('.history-circle').addClass('active');
                }, time);
                time +=180;
            })

            $('.history-year').each((index, element) => {
                setTimeout(function() {
                    $(element).find('.history-circle').removeClass('active');
                }, time);
                time +=130;
            });
        }else if(scroll_pos < end_years_pos){
            historyPlayed = false;
        }
    }
    
})

if($('.history-year').length !== 0) {
    $('.history-year').on('mouseenter',function() {
        $(this).find('.history-circle').addClass('active');
    })
    $('.history-year').on('mouseleave',function() {
        $(this).find('.history-circle').removeClass('active');
    })
}
