$(".view-image").on('click', function() {
    let mq = window.matchMedia("(min-width: 768px)");
    if(mq.matches) {
        $('#image-preview').attr('src', this.children[0].getAttribute("src"));

        $(".image-modal").css("display","block");

        $(".close").on('click', function() { 
            $(".image-modal").css("display","none");
        });

        $(document).on('click', function (e) {
            if ($(e.target).is('.image-modal')) {
                $('.image-modal').fadeOut(400);
            }
        });
    }
});