// @ts-ignore
$('.carousel-pricing').slick({
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                centerMode: true,
                centerPadding: '25%',
                initialSlide: 1,
                slidesToShow: 1,
                slidesToScroll: 1,
                focusOnSelect: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                centerMode: true,
                centerPadding: '15%',
                initialSlide: 1,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 450,
            settings: {
                centerMode: true,
                centerPadding: '20px',
                initialSlide: 1,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});
