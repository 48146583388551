$("#nav-search").on('submit', function(e:Event) {
    search(e);
});

$("#tag-search").on('submit', function(e:Event) {
    search(e);
});

$("#footer-contact").on('submit', function(e:Event) {
    footerContact(e);
});

$("#contact-form").on('submit', function(e:Event) {
    contact(e);
});

$("#email-form").on('submit', function(e:Event) {
    email(e);
});

function email(e:Event) {
    e.preventDefault();
    $('#email-alert').removeClass('d-none');
    $('#email-form input').val("");
    window.location.replace(window.location.href.split(/[#]/)[0] + '#stay-email');
}

function search(e:Event) {
    e.preventDefault();
    window.location.replace('/?page=search_results');
}

function footerContact(e:Event) {
    e.preventDefault();
    $('#footer-alert').removeClass('d-none');
    $('#footer input, #footer textarea').val("");
    window.location.replace(window.location.href.split(/[#]/)[0] + '#footer');
}

function contact(e:Event) {
    e.preventDefault();
    $('#contact-alert').removeClass('d-none');
    $('#contact-form input, #contact-form textarea').val("");
    window.location.replace(window.location.href.split(/[#]/)[0] + '#contact-form');
}