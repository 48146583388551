let menuOpen:boolean = false;

$(window).on('scroll load resize', function () {
    let nav = $(".navbar");
    let mq = window.matchMedia("(min-width: 992px)");
    if(mq.matches){
        $('.navbar').removeClass('navbar-open');
        if($(this).scrollTop() > (nav.height()+200) && $(this).scrollTop() <= (nav.height() + 500)){
            nav.addClass("invisible-nav");
            nav.removeClass("visible-nav");
            nav.removeClass("scrolled");
        }else if($(this).scrollTop() > (nav.height() + 500)) {
            nav.addClass("visible-nav");
            nav.removeClass("invisible-nav");
            nav.addClass("scrolled");
            nav.addClass("sticky-top");
        }else {
            nav.addClass("visible-nav");
            nav.removeClass("invisible-nav");
            nav.removeClass("sticky-top");
            nav.removeClass("scrolled");
        }
    }else {
        nav.addClass("sticky-top");
        if(menuOpen) nav.addClass('navbar-open');

        if($(this).scrollTop() > (nav.height() + 500) && !menuOpen) {
            nav.addClass("scrolled");
        } else {
            nav.removeClass("scrolled");
        }
    }
});

$('.navbar-collapse').on('shown.bs.collapse', function () {
    $('.navbar-toggler .navbar-toggler-icon').addClass('active');
    menuOpen = true;
    let mq = window.matchMedia("(max-width: 991.98px)");
    if(mq.matches) {
        $('body').addClass('noscroll');
        $('.navbar').addClass('navbar-open');
        $('.navbar').removeClass('scrolling-down');
        $('.navbar').addClass('scrolling-up');
        $('.navbar').removeClass("scrolled");
    }
});

$('.navbar-collapse').on('hidden.bs.collapse', function () {
    $('.navbar-toggler .navbar-toggler-icon').removeClass('active');
    menuOpen = false;
    let mq = window.matchMedia("(max-width: 991.98px)");
    if(mq.matches) {
        $('body').removeClass('noscroll');
        $('.navbar').removeClass('navbar-open');
        if($(window).scrollTop() > ($('.navbar').height() + 500)) {
            $('.navbar').addClass("scrolled");
        }
    }
});

$('.dropdown').on('show.bs.dropdown', function () {
    this.classList.add('dropdown-open');
});

$('.dropdown').on('hide.bs.dropdown', function () {
    this.classList.remove('dropdown-open');
});

let previousScroll = 0;
$(window).on('scroll resize', function(){
    let mq = window.matchMedia("(max-width: 991.98px)");
    let scroll = $(this).scrollTop();

    if(mq.matches) {
        if (scroll > previousScroll){
            if($('.navbar-collapse.show').length === 0) {
                $('.navbar').removeClass('scrolling-up');
                $('.navbar').addClass('scrolling-down');
            }
        } else {
            $('.navbar').removeClass('scrolling-down');
            $('.navbar').addClass('scrolling-up');
        }
    } else {
        $('.navbar').removeClass('scrolling-up');
        $('.navbar').removeClass('scrolling-down');
    }
    previousScroll = scroll;
});

function calcWidth() {
    let resizing:boolean = true;
    let navwidth:number;
    let availablespace:number;
    do{
        navwidth = 0;
        $('.navbar-nav li').each(function() {
            navwidth += $(this).outerWidth(true);
        });
        availablespace = $('.navbar-nav').outerWidth(true);

        if (navwidth > availablespace) {
            collapse();
        } else {
            expand();
        }

        let maxIteration =  $('.navbar-nav li').length;
        while($(document).width() > 992 && (Math.round(window.innerWidth) < Math.round($(document).outerWidth(true)))) {
            if(maxIteration === 0) break;
            collapse();
            maxIteration--;
        }

        maxIteration =  $('.navbar-nav li').length;
        while($(document).width() > 992 && (Math.round(($('.navbar').outerWidth(true))) < Math.round($('.navbar-collapse').outerWidth(true) + $('.navbar-brand').outerWidth(true)))){
            if(maxIteration === 0) break;
            collapse();
            resizing=false;
            maxIteration--;
        }

        if(($(document).width() > 1200 && $(document).width() < 1980) && ($('.navbar-collapse').outerWidth(true)) < ($('.navbar-nav').outerWidth(true) + $('.collapse .form-inline').outerWidth(true) + $('.hidden-collapsed-menu').css("display")=="none" ? 0 : $('.hidden-collapsed-menu').outerWidth(true))) {
            collapse();
            resizing = false;
            maxIteration--;
        }

        if(($(document).width() < 1200 && $(document).width() > 992) && ($('.navbar-collapse').outerWidth(true)) < ($('.navbar-nav').outerWidth(true) + $('.hidden-collapsed-menu').css("display")=="none" ? 0 : $('.hidden-collapsed-menu').outerWidth(true))) {
            collapse();
            maxIteration--;
        }

        if(Math.round(window.innerWidth) == 992 || Math.round(window.innerWidth) == 993) {
            collapse();
            resizing = false;
        }

        if ($('.hidden-menu li').length > 0) {
            $('.hidden-collapsed-menu').css('display','inline-block');
        } else {
            $('.hidden-collapsed-menu').css('display','none');
        }

        function collapse(){
            let lastItem = $('.navbar-nav li').last();
            lastItem.attr('data-width', lastItem.outerWidth(true));
            lastItem.prependTo($('.hidden-menu'));
        }

        function expand(){
            let firstMoreElement = $('.hidden-menu li').first();
            if (navwidth + firstMoreElement.data('width') < availablespace) {
                firstMoreElement.appendTo($('.navbar-nav'));
            }else{
                resizing = false;
            }
        }
    }while(resizing === true)
}

$(window).on('load resize',function(){
    if($('.form-inline').first().hasClass('searching')) {
        return;
    }
    let mq = window.matchMedia("(min-width: 992px)");
    if(mq.matches) {
        $('body').removeClass('noscroll');
        calcWidth();
    } else {
        while($('.hidden-menu li').length > 0){
            $('.hidden-menu li').first().detach().appendTo($('.navbar-nav'));
        }
        $('.hidden-collapsed-menu').css('display','none');
    }
});

$('.navbar-nav li a').each(function() {
    this.innerHTML = this.innerHTML.replace(' ', '&nbsp');
});
