$(window).on('resize load',function(){
    if($('.news-detail-date-container').length !== 0 && $('.news-detail-image-top').length !== 0){
        let mq = window.matchMedia('(max-width: 767.98px)');
        if(mq.matches){
            let top:number = $('.news-detail-image-top').height()+10;
            $('.news-detail-date-container').css('top','-'+top+"px");
        }
    }

    let mq = window.matchMedia("(max-width: 575.98px)");

    if(mq.matches) {
        if($('.news-detail-image-top .news-detail-tags-container').length !== 0) {
            $('.news-detail-image-top .news-detail-tags-container').detach().prependTo($('.news-detail-article-container'));
        }
    } else {
        if($('.news-detail-article-container .news-detail-tags-container').length !== 0) {
            $('.news-detail-article-container .news-detail-tags-container').detach().appendTo($('.news-detail-image-top'));
        }
    }
});
