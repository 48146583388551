$(window).on('load', function(){
    removeLoader();
});
function removeLoader(){
  $(".loader").fadeOut(500, function() {
    $(".loader").remove();
  });  
}

$(document).ready(function() {
  if($('.hero-heading').length !== 0){
    $('.hero-heading').addClass('hover');
    $('.hero-subheading').addClass('hover');
    $('.hero-text').addClass('hover');
    $('.hero-call-to-action').addClass('hover');
  }
});