$(window).on('load scroll', function() {
    let footer_pos = $('.footer-scroll-up').offset().top;
        let scroll_pos = window.pageYOffset+(window.innerHeight/1.15);

        if(scroll_pos > footer_pos) {
            if($('.footer-scroll-up').length!=0) {
                $('.footer-scroll-up a').addClass('jumpOnScroll');
            }
        }else if(scroll_pos < footer_pos){
            if($('.footer-scroll-up').length!=0) {
                $('.footer-scroll-up a').removeClass('jumpOnScroll');
            }
        }
})
$('.current-year').html(new Date().getFullYear().toString());