$('#search-btn').on('click', function(){
    if($('.navbar-nav').css('display') === "none"){
        $('.navbar-nav').css('display','flex');
        $('.form-inline').removeClass('searching');
        $(window).trigger('resize');
    }else {
        $('.navbar-nav').css('display','none');
        $('.form-inline').addClass('searching');
        $('.hidden-collapsed-menu').css('display','none');
    }
});

$(window).on('resize', function(){
    let mq = window.matchMedia("(min-width: 1200px)");
    if(!mq.matches){
        $('.navbar-nav').css('display','flex');
        $('.form-inline').removeClass('searching');
    }
});