'use strict';

(() => {
    if (window.matchMedia('(min-width: 992px)').matches) {
        let navItemDropdown = $('.nav-item.dropdown');

        navItemDropdown.on('mouseenter', function () {
            // @ts-ignore
            $(this).children('.dropdown-toggle').dropdown('show');
        });

        navItemDropdown.on('mouseleave', function () {
            // @ts-ignore
            $(this).children('.dropdown-toggle').dropdown('hide');
        });

        let hiddenMenuDropdown = $('.hidden-collapsed-menu.dropdown');

        hiddenMenuDropdown.on('mouseenter', function () {
            // @ts-ignore
            $(this).children('.dropdown-toggle').dropdown('show');
        });

        hiddenMenuDropdown.on('mouseleave', function () {
            // @ts-ignore
            $(this).children('.dropdown-toggle').dropdown('hide');
        });
    }
})();
