let specializationLoaded:boolean = false;

$(window)
    .on('load', function() {
        makeSpecializationLines();
    })
    .on('scroll', function() {
        makeSpecializationLines();
    });

function makeSpecializationLines(){
    if($('.about-top-specialization-line').length!=0 && !specializationLoaded){
        let specialization_pos = $('.about-top-specialization-line').offset().top;
        let scroll_pos = window.pageYOffset+(window.innerHeight/1.2);

        if(scroll_pos > specialization_pos) {
            $('.about-top-specialization-line-used').each(function () {
                let used = this.getAttribute('data-value') + '%';
                $(this).css('width', used);
        
                let left = $(this).css('width');
                $(this).css({left:'-'+left}).animate({"left":"0"}, "slow");
                specializationLoaded = true;
            })
        }
    }
}