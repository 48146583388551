$(".carousel-clients .carousel-item").each(function() {
    let next = $(this).next();
    if (!next.length) {
        next = $(this).siblings(':first');
    }
    next.find('.carousel-slide').children(':first-child').clone().appendTo($(this).find('.carousel-slide'));

    for (let n = 0; n < 3; n++){
        if(next.next().length!=0) next = next.next()
        else next = $(this).siblings(":first");
        next.children(":first-child").children(":first-child").clone().appendTo($(this).find('.carousel-slide'));
    }
});

let mq = window.matchMedia("(min-width: 992px)");

$( ".carousel-slide:last-child .about-top-clients-item:nth-last-child(2), .carousel-slide:last-child .our-clients-item:nth-last-child(2)" ).on( "click", function(e) {
    if(mq.matches) turnClients(e,"next");
});

$( ".carousel-slide:first-child .about-top-clients-item:nth-child(2), .carousel-slide:first-child .our-clients-item:nth-child(2)" ).on( "click", function(e) {
    if(mq.matches) turnClients(e,"prev");
});

$( ".carousel-slide .about-top-clients-item:first-child, .carousel-slide .our-clients-item:first-child" ).on( "click", function(e) {
    if(mq.matches) {
        turnClients(e,"prev");
        turnClientsOnceMore(e,"prev");
    }
});

$( ".carousel-slide .about-top-clients-item:last-child, .carousel-slide .our-clients-item:last-child" ).on( "click", function(e) {
    if(mq.matches) {
        turnClients(e,"next");
        turnClientsOnceMore(e,"next");
    }
});

function turnClients(e:any, direction:string){
    e.preventDefault();
    (<any>$('.carousel-clients')).carousel(direction);
}

function turnClientsOnceMore(e:any,direction:string){
    let turned:boolean = false;
    $(".carousel-clients").on('slid.bs.carousel', function () {
        if(turned==false){
            turnClients(e,direction);
            turned=true;
        }
    });
}
